import { faSync, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  FinishButton,
  Input,
  MainButton,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import {
  AnswerKakeru,
  AnswerList,
  Counter,
  Result,
  SingleAnswer,
  TitleDetail,
} from '../../AnswerList';
import { questions } from './Words.json';

interface Stage2AnswerProps {
  turn: number;
}

export type Item = {
  title: string;
  items: string[];
  ideas: string[];
};

const MAX_ANSWER_LENGTH = 30;

const Create3Answer: React.FC<Stage2AnswerProps> = props => {
  const location = useLocation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentQuestion = questions[props.turn];
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet, setResultSet] = useState<any>(
    `create-${trainingId}-resultSet`
  );
  const [tempIdea, setTempIdea] = useState<string>(
    `create3-${trainingId}-tempIdea`,
    ''
  );
  const [tempIdeasVec, setTempIdeasVec] = useState<string[]>(
    `create3-${trainingId}-tempIdeasVec`,
    []
  );
  const [tempItemsVec, setTempItemsVec] = useState<string[]>(
    `create3-${trainingId}-tempItemsVec`,
    []
  );
  const [sendCount, setSendCount] = useState<number>(
    `create3-${trainingId}-sendCount`,
    0
  );
  const [finished, setFinished] = useState<boolean>(
    `create3-${trainingId}-finished`,
    false
  );
  const shuffle = ([...array]) => {
    for (let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const [currentItems, setCurrentItems] = useState<string[]>(
    `create3-${trainingId}-currentItems`,
    shuffle(questions[props.turn].items)
  );

  const listRef = React.useRef<HTMLUListElement>(null);
  const listContentRef = React.useRef<HTMLLIElement>(null);

  useEffect(() => {
    setFinished(false);
  }, [pathname]);

  useEffect(() => {
    listRef.current?.scrollTo({
      top: listRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [sendCount]);

  const goTo = (url: string) => {
    window.setTimeout(() => {
      navigate(url);
    }, 0);
  };

  const send = () => {
    if (tempIdea === '') return;
    if (tempIdea.length > MAX_ANSWER_LENGTH) return;

    setTempItemsVec([...tempItemsVec, currentItems[0]]);
    setTempIdeasVec([...tempIdeasVec, tempIdea]);
    currentItems.shift();

    if (currentItems.length == 0) {
      setCurrentItems(shuffle(currentQuestion.items));
    } else {
      setCurrentItems([...currentItems]);
    }

    setTempIdea('');
    setSendCount(sendCount + 1);
  };

  const pass = () => {
    if (currentItems.length == 0) return;
    const skipItem = currentItems.shift();
    if (skipItem) currentItems.push(skipItem);
    setCurrentItems([...currentItems]);
  };

  const next = () => {
    const sendData: Item = {
      title: currentQuestion.title,
      items: tempItemsVec,
      ideas: tempIdeasVec,
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const results: Item[] = resultSet?.stage3 || [];

    let _exist = false;
    results.map((single, i) => {
      if (single.title == sendData.title) {
        _exist = true;
        results[i] = sendData;
      }
    });

    if (_exist == false) {
      results.push(sendData);
    }

    const _resultSet = resultSet;
    _resultSet.stage3 = results;

    if (props.turn == 0) {
      setResultSet(_resultSet);
      setTempIdea('');
      setTempIdeasVec([]);
      setTempItemsVec([]);
      setSendCount(0);
      goTo(`/${trainingId}/create3/introduction2`);
    } else {
      setResultSet(_resultSet);
      goTo(`/${trainingId}/create3/result`);
    }
  };

  const deleteIdea = (i: number) => {
    const tmp1 = tempItemsVec;
    tmp1.splice(i, 1);
    setTempItemsVec([...tmp1]);
    const tmp2 = tempIdeasVec;
    tmp2.splice(i, 1);
    setTempIdeasVec([...tmp2]);
  };

  const Answers = tempItemsVec.map((p, i) => {
    return (
      <SingleAnswer
        key={i}
        ref={listContentRef}
        animate={i == tempItemsVec.length - 1}
      >
        <div>
          <span>
            <p>{i + 1}</p>
          </span>
        </div>
        <div>
          <AnswerWrap>
            <div>
              <AnswerKakeru />
              <p>{p}</p>
            </div>
            <div>
              <p>{tempIdeasVec[i]}</p>
            </div>
          </AnswerWrap>
          <div onClick={() => deleteIdea(i)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </SingleAnswer>
    );
  });

  return (
    <Wrap>
      <TimeGauge
        timerKey={trainingId + '-create3-' + props.turn}
        duration={60 * 15}
        stageName={InstructionText[3].title}
        logo={InstructionLogo}
        color={InstructionColor}
        onSkip={() => {
          next();
        }}
        onFinished={() => {
          setFinished(true);
        }}
        onBack={() => {
          navigate(`/${trainingId}/stages`);
        }}
      />
      <Wrapper>
        <Main>
          <View>
            <Theme primary>
              <h2>お題</h2>
              <div>
                <p>{currentQuestion.title}</p>
              </div>
            </Theme>

            <IconWrapper>
              <Kakeru />
            </IconWrapper>

            <Theme>
              <h2>掛け算アイテム</h2>
              <div>
                <p>{currentItems[0]}</p>
                <Pass>
                  <Button
                    variant='sub'
                    color='negative'
                    size='medium'
                    onClick={() => pass()}
                  >
                    <FontAwesomeIcon icon={faSync} />
                    パス
                  </Button>
                </Pass>
              </div>
            </Theme>

            <InputWrapper>
              <Input
                maxLength={MAX_ANSWER_LENGTH}
                disabled={finished}
                placeholder='アイデアを入力してください'
                value={tempIdea}
                onChange={(val: string) => {
                  setTempIdea(val);
                }}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    send();
                  }
                }}
              />
              <MainButton
                disabled={
                  tempIdea == '' ||
                  finished ||
                  tempIdea.length > MAX_ANSWER_LENGTH
                }
                color='positive'
                size='medium'
                onClick={() => send()}
              >
                送信
              </MainButton>
            </InputWrapper>
          </View>
        </Main>

        <Result>
          <h3>
            <TitleDetail />
          </h3>
          <AnswerList ref={listRef}>{Answers}</AnswerList>
          <Counter>
            <span>回答数</span>
            <p>{tempItemsVec.length}</p>
          </Counter>
        </Result>
      </Wrapper>

      <FinishButton
        color={InstructionColor}
        finished={finished}
        click={() => {
          next();
        }}
      >
        {props.turn == 0
          ? '次のお題に挑戦しましょう！'
          : '以上で実習は終了です。お疲れ様でした！'}
      </FinishButton>
    </Wrap>
  );
};

const AnswerWrap = styled.div`
  width: calc(100% - 8px);

  > div:first-child {
    margin: 4px;
    padding: 4px;
    background: #f2a118;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  > div:last-child {
    padding: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
  }
  svg {
    margin: 0 4px;
  }
  p {
    overflow-wrap: anywhere;
  }
`;

const Main = styled.div`
  flex: 1;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;

  button {
    margin-left: 12px;
    margin-top: -7px;
  }

  margin-bottom: 40px;
`;

const IconWrapper = styled.div`
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 14px;
`;

const Wrap = styled.div`
  width: 100%;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const View = styled.div`
  flex: 1;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  justify-content: center;

  align-items: center;
`;

interface ThemeProps {
  primary?: boolean;
}

const Pass = styled.div`
  display: block;
  position: absolute;
  right: 10px;
  top: 52px;

  button {
    display: flex;
    align-items: center;
  }

  svg {
    display: block;
    margin-top: -1px;
    margin-right: 10px;
  }
`;

const Theme = styled.div`
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 20px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  position: relative;

  > div {
    border: solid 1px #ced4da;
    border-top: none;
    padding: 30px 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 110px;

    > span {
      position: absolute;
      bottom: 16px;
    }
  }

  p {
    text-align: center;
    font-size: 4.6rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h2 {
    background: #f2a118;
    color: white;
    font-size: 2.6rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 4px;
  }

  ${(p: ThemeProps) => {
    return p.primary
      ? `
      max-width: 820px;

      > div {
        border: 4px solid #f2a118;
        border-top: none;
        
        p {
          color: #f2a118;
        }
      }
    `
      : `
      `;
  }}
`;

const Kakeru: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='52'
      height='52'
      viewBox='0 0 52 52'
    >
      <path
        d='M-9902-6382.474l-20.223,20.224-5.777-5.778,20.221-20.222L-9928-6408.474l5.777-5.776,20.223,20.222,20.221-20.222,5.779,5.776-20.223,20.224,20.223,20.222-5.779,5.778Z'
        transform='translate(9928.002 6414.25)'
        fill='#868e96'
      />
    </svg>
  );
};

export default Create3Answer;
