import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { deleteData, hasData } from '../../../localStorage/LocalStorageManager';
import Dialog from '../../../uiElements/Dialog';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const text = InstructionText[2];

const Create2Rule: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet] = useState<any>(`create-${trainingId}-resultSet`, {
    stage1: null,
  });
  const goTo = (url: string) => {
    navigate(url, { state: resultSet });
  };
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);

  return (
    <CommonBG>
      <Rule
        title={text.title}
        mission={text.mission}
        description={text.description}
        video={text.video}
        onStart={
          trainingId && hasData('create2', trainingId)
            ? () => {
                setIsShowModal(true);
              }
            : () => navigate(`/${trainingId}/create2/countdown1`)
        }
        onBack={() => goTo(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      />
      <Dialog
        message={
          '前回の進捗があります。続きから始めますか？\n新規に始める場合は「新規に始める」を選択してください。'
        }
        isShow={isShowModal}
        cancelText={'新規に始める'}
        onCancel={() => {
          if (!trainingId) {
            return;
          }
          deleteData('create2', trainingId);
          navigate(`/${trainingId}/create2/countdown1`);
        }}
        onConfirm={() => {
          const currentPage =
            localStorage.getItem('currentpage-create2') ?? 'countdown1';
          navigate(`/${trainingId}/create2/${currentPage}`);
        }}
      />
    </CommonBG>
  );
};

export default Create2Rule;
