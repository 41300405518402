import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { DefaultAuthContext } from './components/auth/AuthContext';
import AuthErrorPage from './components/eventProvider/common/AuthErrorPage';
import RedirectPage from './components/eventProvider/common/RedirectPage';
import Store from './redux/store';
import { AuthContext, TrainingRouter } from './TrainingRouter';

export const App = () => {
  return <Router />;
};

export default App;

const Router = () => {
  return (
    <AuthContext.Provider value={DefaultAuthContext}>
      <Provider store={Store}>
        <BrowserRouter>
          <Routes>
            <Route path='/auth-error' element={<AuthErrorPage />} />
            <Route path='/:trainingId' element={<TrainingRouter />} />
            <Route path='/:trainingId/*' element={<TrainingRouter />} />
            <Route path='/' element={<RedirectPage />} />
            <Route path='/*' element={<Navigate to='/' />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </AuthContext.Provider>
  );
};
