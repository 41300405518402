import { CommonBG, Result } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import {
  Checked,
  Elements,
  ElementsListWrapper,
  Item,
  SelectedItems,
  SingleElement,
  TitleElement,
  getStr,
  word,
} from './Create2Answer';
import Simulated from './Simulated.json';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

const Create2Result: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet, setResultSet] = useState<any>(
    `create-${trainingId}-resultSet`
  );

  const goTo = (url: string) => {
    window.setTimeout(() => {
      navigate(url);
    }, 0);
  };

  const text = InstructionText[2];

  const ElementsList = (props: any) => {
    let words: any[] = [];
    (resultSet?.stage1 == null ? Simulated : resultSet.stage1).map(
      (single: any) => {
        if (single.title == props.item.title) {
          words = single.ideas;
        }
      }
    );

    const dummy_elements: word[] = words.map((single: string, i: number) => {
      return { id: i, word: single };
    });

    return (
      <ElementsListWrapper>
        {dummy_elements.map((s: word, i) => {
          const finished = props.item.elements[s.id].length > 0;

          return (
            <SingleElement
              key={i}
              style={{
                background: finished ? '#CED4DA' : 'white',
                transition: '0.2s !important',
                boxShadow: finished ? 'none' : '0 3px 6px rgba(0, 0, 0, 0.16)',
              }}
            >
              <div>
                {finished && (
                  <i>
                    <Checked />
                  </i>
                )}
                <p
                  style={{
                    opacity: finished ? '0.3' : '1.0',
                  }}
                >
                  {s.word}
                </p>
              </div>
              <SelectedItems>
                {props.item.elements[s.id].map((single: any, t: number) => {
                  return getStr(single, t, null);
                })}
              </SelectedItems>
            </SingleElement>
          );
        })}
      </ElementsListWrapper>
    );
  };

  return (
    <CommonBG>
      <Result
        title={text.title}
        mission={text.mission}
        video={text.video_result}
        onBack={() => {
          setResultSet(resultSet);
          goTo(`/${trainingId}/stages`);
        }}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        {resultSet != null && (
          <>
            {resultSet.stage2 && (
              <ResultList>
                {resultSet.stage2.map((single: Item, i: any) => {
                  return (
                    <SingleResult key={i}>
                      <Title>
                        <h3>お題</h3>
                        <div>
                          <p>{single.title}</p>
                        </div>
                      </Title>
                      <Elements>
                        <h2>
                          <TitleElement />
                        </h2>
                        <ElementsList item={single} />
                      </Elements>
                    </SingleResult>
                  );
                })}
              </ResultList>
            )}
          </>
        )}
      </Result>
    </CommonBG>
  );
};

const ResultList = styled.ul`
  li {
    box-shadow: none !important;
  }
`;

const SingleResult = styled.li`
  list-style: none;
`;

const Title = styled.div`
  display: flex;
  margin-bottom: 10px;

  h3 {
    width: 120px;
    background: #f2a118;
    color: white;
    font-weight: bold;
    font-size: 2.4rem;
    text-align: center;
    padding-top: 3px;
  }

  > div {
    background: white;
    padding: 4px 0;

    border: 1px solid #ced4da;
    border-left: none;
    flex: 1;
    display: flex;

    p {
      font-weight: bold;
      font-size: 2.4rem;
      flex: 1;
      margin: 0 16px;
    }

    > p {
      line-height: 3.4rem;
    }
  }
`;

export default Create2Result;
