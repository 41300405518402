import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommonBG, Result } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { AnswerKakeru } from '../../AnswerList';
import { Item } from './Create3Answer';

interface RuleInstrctionProps {
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

interface SingleResultProps {
  single: Item;
}

const SingleResult: React.FC<SingleResultProps> = props => {
  const [openDetail, setOpenDetail] = React.useState<boolean>(false);
  const detailRef = React.useRef<HTMLDivElement>(null);

  const detailHeight = detailRef.current?.offsetHeight || 0;

  React.useEffect(() => {
    setOpenDetail(true);
  }, [detailHeight]);

  return (
    <SingleResultBody>
      <Title
        onClick={() => {
          setOpenDetail(!openDetail);
        }}
      >
        <h3>お題</h3>
        <div>
          <p>{props.single.title}</p>
          <Icon>
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{
                transform: openDetail ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </Icon>
        </div>
      </Title>
      <Details open={openDetail} style={{ maxHeight: detailHeight }}>
        <div ref={detailRef}>
          {props.single.ideas.length > 0 ? (
            <ul>
              {props.single.ideas.map((single: string, id: number) => {
                return (
                  <li key={id}>
                    <span>
                      <AnswerKakeru />
                      {props.single.items[id]}
                    </span>
                    <p>{single}</p>
                  </li>
                );
              })}
            </ul>
          ) : (
            <NoContent>
              <small>(項目なし)</small>
            </NoContent>
          )}
        </div>
      </Details>
    </SingleResultBody>
  );
};

const Create3Result: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet, setResultSet] = useState<any>(
    `create-${trainingId}-resultSet`
  );

  const goTo = (url: string) => {
    window.setTimeout(() => {
      navigate(url);
    }, 0);
  };

  const text = InstructionText[3];

  return (
    <CommonBG>
      <Result
        title={text.title}
        mission={text.mission}
        video={text.video_result}
        onBack={() => {
          setResultSet(resultSet);
          goTo(`/${trainingId}/stages`);
        }}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        {resultSet.stage3 && (
          <>
            {resultSet.stage3.map((single: Item, i: number) => {
              return <SingleResult single={single} key={i} />;
            })}
          </>
        )}
      </Result>
    </CommonBG>
  );
};

const Icon = styled.div`
  width: 30px;
  font-size: 1.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    transition: 0.2s;
  }
`;

const SingleResultBody = styled.div`
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;

  small {
    opacity: 0.4;
  }
`;

const Title = styled.div`
  display: flex;
  cursor: pointer;

  h3 {
    width: 120px;
    background: #f2a118;
    color: white;
    font-weight: bold;
    font-size: 2.4rem;
    text-align: center;
    padding-top: 3px;
  }

  > div {
    background: white;
    padding: 4px 0;

    border: 1px solid #ced4da;
    border-left: none;
    flex: 1;
    display: flex;

    p {
      font-weight: bold;
      font-size: 2.4rem;
      flex: 1;
      margin: 0 16px;
    }

    > p {
      line-height: 3.4rem;
    }

    span {
      font-weight: bold;
      font-size: 2.4rem;
      display: flex;
      align-items: baseline;
      white-space: nowrap;

      p:first-child {
        font-size: 1.3rem;
        margin-right: 0px;
      }
    }
  }
`;

interface DetailProps {
  open: boolean;
}

const Details = styled.div`
  border: 1px solid #ced4da;
  border-top: none;
  background: #f3f4f6;
  overflow: hidden;
  transition: 0.4s;

  p {
    font-size: 1.8rem;
    font-weight: bold;
    padding: 10px 16px;
    overflow-wrap: anywhere;
  }

  ul {
    padding: 4px;
    padding-top: 8px;

    li {
      display: flex;
      background: white;
      list-style: none;
      margin: 4px;
      margin-top: 0;

      span {
        font-size: 1.6rem;
        border: 2px solid #f2a118;
        width: 180px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #f2a118;
        color: white;
        font-weight: bold;
        padding: 0 12px;
        padding-bottom: 4px;

        svg {
          margin-top: 2px;
          margin-right: 10px;
        }
      }

      > p {
        flex: 1;
        text-align: center;
        border: 1px solid #ced4da;
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 2.5rem;
      }
    }
  }

  ${(p: DetailProps) => {
    return p.open
      ? ``
      : `
        max-height: 0px !important;
    `;
  }}
`;

const NoContent = styled.div`
  font-size: 1.6rem;
  padding: 10px 20px;
  text-align: center;

  small {
    margin: 0 auto;
  }
`;

export default Create3Result;
