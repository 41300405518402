import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const text = InstructionText[3];

const Program3Rule2: React.FC<RuleInstrctionProps> = () => {
  const location = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet, setResultSet] = useState<any>(
    `create-${trainingId}-resultSet`,
    location.state
  );

  const navigate = useNavigate();
  const goTo = (url: string) => {
    setResultSet(resultSet);
    navigate(url);
  };

  return (
    <CommonBG>
      <Rule
        title={text.title}
        mission={text.mission}
        description={text.description}
        video={text.video}
        onStart={() => goTo(`/${trainingId}/create3/countdown2`)}
        color={InstructionColor}
        logo={InstructionLogo}
      />
    </CommonBG>
  );
};

export default Program3Rule2;
