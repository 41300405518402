import { MainButton, TextWindow } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Colors from '../../styles/colors';

interface DialogProps {
  message?: string;
  onConfirm?: () => void;
  confirmText?: string;
  onCancel: () => void;
  cancelText?: string;
  isShow?: boolean;
}

const Dialog: React.FC<DialogProps> = props => {
  const { message, onConfirm, confirmText, onCancel, cancelText } = props;

  if (props.isShow == false) return <></>;

  return (
    <ErrorOverlayWrapper>
      <Text bracket dark>
        <p>{message ?? '申し訳ありません。エラーが発生しました。'}</p>
        <Buttons>
          <MainButton size={'large'} color={'negative'} onClick={onCancel}>
            {cancelText ?? '閉じる'}
          </MainButton>
          {onConfirm !== undefined && (
            <MainButton size={'large'} color={'positive'} onClick={onConfirm}>
              {confirmText ?? 'OK'}
            </MainButton>
          )}
        </Buttons>
      </Text>
    </ErrorOverlayWrapper>
  );
};

const Text = styled(TextWindow)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
`;

const ErrorOverlayWrapper = styled.div`
  position: fixed;
  background-color: ${Colors.gray8_op090};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 11;
`;

const Buttons = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  padding: 2rem 0;
  left: 50%;

  button {
    margin-right: 4rem;
    box-shadow: none !important;
    transform: initial !important;
    cursor: pointer;
  }

  button:last-child {
    margin-right: 0;
  }
`;

export default Dialog;
