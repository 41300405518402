import { CommonBG } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Create1Field from './Create1Field';

interface Create1GameProps {
  stage: number;
}

const Create1Game: React.FC<Create1GameProps> = props => {
  return (
    <CommonBG>
      <MainWrapper>
        <Create1Field turn={props.stage - 1} />
      </MainWrapper>
    </CommonBG>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 4rem);
  margin: 4rem auto 0;
  display: flex;
  overflow: hidden;
`;

export default Create1Game;
