import { InstructionColors } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';

export const InstructionLogo: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='265.32'
      height='60'
      viewBox='0 0 265.32 60'
    >
      <g transform='translate(-848 -114)'>
        <path
          d='M35.62,24.84V23.16H24.26v.88c0,1.52-.88,2.84-3.64,2.84H19.3c-2.56,0-4.28-.92-4.28-3.8V18.56c0-2.6.96-3.96,4.12-3.96h1.28c2.52,0,3.64.84,3.64,2.52v.44H35.54v-1.6C35.54,8.12,31.1,3.8,20.42,3.8H18.58C6.82,3.8,3.06,7.96,3.06,18.56v4.52c0,10.96,4.16,14.52,15.68,14.52h1.88C31.22,37.6,35.62,33.76,35.62,24.84Zm28.72-1.28V12.92H51.66c-6.8,0-12.56,4.04-12.56,13.64V37.08H50.58V26.8c0-1.6,1.16-3.24,3.64-3.24ZM97.5,21.2V19.92c0-4.36-2.6-7.16-7.84-7.16H75.5c-5.72,0-8.04,3.76-8.04,7.44v7.6c0,6.52,2.56,9.28,8.72,9.28H96.86V29H79.58c-1.28,0-1.64-.6-1.72-1.4H90.1C95.82,27.6,97.5,25.2,97.5,21.2Zm-10.76-.32c0,.6-.44.84-1.04.84H77.82v-.36a1.538,1.538,0,0,1,1.72-1.56H85.7c.68,0,1.04.4,1.04.88Zm45,9.84V22.4c0-6.12-2-9.56-8.92-9.56H102.14v8.2h16.8c1.12,0,1.48.44,1.48,1.28H108.58c-4.84,0-7.68,2.48-7.68,6.32v1.68c0,3.88,2.92,6.76,8.2,6.76h14.84C129.06,37.08,131.74,34.56,131.74,30.72Zm-11.52-1.44c0,.48-.2.68-.88.68H113.3c-.76,0-.96-.28-.96-.68v-.32c0-.4.2-.64.96-.64h6.04c.64,0,.88.32.88.64Zm43.44-5.84V12.92h-13V8.36H139.34v4.56h-5V23.44h5V29.2c0,4.76,2.36,7.88,9,7.88H163.1V26.64h-11c-1.28,0-1.44-.2-1.44-1.28V23.44Zm32.68-2.24V19.92c0-4.36-2.6-7.16-7.84-7.16H174.34c-5.72,0-8.04,3.76-8.04,7.44v7.6c0,6.52,2.56,9.28,8.72,9.28H195.7V29H178.42c-1.28,0-1.64-.6-1.72-1.4h12.24C194.66,27.6,196.34,25.2,196.34,21.2Zm-10.76-.32c0,.6-.44.84-1.04.84h-7.88v-.36a1.538,1.538,0,0,1,1.72-1.56h6.16c.68,0,1.04.4,1.04.88Z'
          transform='translate(916.98 123.4)'
          fill='#fff'
        />
        <g transform='translate(848 114)'>
          <path
            d='M-3725.966-6499.07a3.752,3.752,0,0,1-3.49-2.35h-1.434l-.18-2a1.062,1.062,0,0,1-.539-.167,1.086,1.086,0,0,1-.467-.665l-.054-.261a1.057,1.057,0,0,1,.793-1.253h.054l-.061-.667a1.058,1.058,0,0,1-.732-.773l-.054-.261a1.053,1.053,0,0,1,.135-.793,1.07,1.07,0,0,1,.452-.393l-.333-3.667c-.242-.192-.482-.4-.713-.626a9.377,9.377,0,0,1-2.032-10.254,9.38,9.38,0,0,1,8.685-5.8h.012a9.385,9.385,0,0,1,8.653,5.8,9.388,9.388,0,0,1-2.006,10.225,8.964,8.964,0,0,1-.734.667l-.146,1.553a1.1,1.1,0,0,1,.339.553l.061.26a1.043,1.043,0,0,1-.58,1.18l-.054.8a1.056,1.056,0,0,1,.593.721l.061.261a1.052,1.052,0,0,1-.793,1.251h-.061l-.46,4.308h-1.426A3.752,3.752,0,0,1-3725.966-6499.07Zm-1.29-4.8v1.013a1.323,1.323,0,0,0,.376.949,1.325,1.325,0,0,0,.936.4h.018a1.341,1.341,0,0,0,1.335-1.314v-1.052h1.406l.154-1.694-5.6,1.253v.44Zm4.467-4.708-6.155,1.373.12,1.247.66-.139,5.253-1.188.121-1.293Zm-6.468-2.187.167,1.936.739-.167,5.714-1.28.048-.488Zm4.667-8v0l-.732,4.632v.12l.539.08.86-5.546a.4.4,0,0,0-.254-.447.359.359,0,0,0-.118-.02.359.359,0,0,0-.3.154,2.539,2.539,0,0,0-.187.3l-.007.011c-.239.392-.565.928-1.047.928-.516,0-1.1-.745-1.332-1.065-.025-.031-.046-.061-.064-.087l0,0a.845.845,0,0,0-.061-.082.358.358,0,0,0-.295-.154.365.365,0,0,0-.118.02.4.4,0,0,0-.267.427l1.067,5.553.521-.087-.495-2.579-.179-1.221-.2-1.034a1.924,1.924,0,0,0,1.413.821,1.42,1.42,0,0,0,1.073-.547,1.482,1.482,0,0,0,.113-.133.43.43,0,0,1,.065-.04Z'
            transform='translate(3755.922 6550.093)'
            fill='#fff'
          />
          <path
            d='M44.416,20.287a.86.86,0,0,0,.853-.793l.34-5.08a.853.853,0,0,0-.847-.913H44.1a.853.853,0,0,0-.847.907l.313,5.08A.84.84,0,0,0,44.416,20.287Z'
            transform='translate(-14.416 -4.5)'
            fill='#fff'
          />
          <path
            d='M29.868,26.178a.847.847,0,0,0,.04-1.16l-3.333-3.84a.972.972,0,0,1-.1-.08l-.08-.053a.793.793,0,0,0-.24-.113h-.067a.853.853,0,0,0-.753.213l-.467.467a.853.853,0,0,0,0,1.233l3.813,3.373A.853.853,0,0,0,29.868,26.178Z'
            transform='translate(-8.202 -6.971)'
            fill='#fff'
          />
          <path
            d='M62.375,21.7l-.46-.467a.84.84,0,0,0-.733-.24h-.067a.793.793,0,0,0-.24.113l-.08.053a1.114,1.114,0,0,0-.107.08l-3.373,3.82a.853.853,0,0,0,1.2,1.2l3.84-3.333a.86.86,0,0,0,.02-1.227Z'
            transform='translate(-19.042 -6.995)'
            fill='#fff'
          />
          <path
            d='M30,0A30,30,0,1,0,60,30,30,30,0,0,0,30,0Zm0,57.6A27.6,27.6,0,1,1,57.6,30,27.6,27.6,0,0,1,30,57.6Z'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  );
};

export const SpeccText = {
  title: 'Create',
  mission: '自由に考えひらめく力を身につける。',
  description_start: '説明(開始)',
  description_finish: '説明(終了)',
  video:
    'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
};

interface InstructionText {
  title: string;
  mission: string;
  description: string;
  video: string;
  video_result: string;
}

export const InstructionText: { [v: number]: InstructionText } = {
  1: {
    title: '素材分解トライアル',
    mission: '思考のタネとなる「要素」を抽出する。',
    description:
      '出題されるお題を構成する要素・特徴を制限時間内に出来るだけ沢山書き出しましょう。身近な物から「アイデアのタネ」を集める練習になります。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
  2: {
    title: 'チェックリスト大喜利',
    mission: '「要素」を様々な方法で変形してみる。',
    description:
      '『オズボーンのチェックリスト』というメソッドを利用し、先ほど分解した要素を変化させて新たなアイデアを生み出しましょう。先ほどのワークで分解した「アイデアのタネ」とチェックリストのイメージを掛け合わせることで、新たな発想を得ることが出来ます。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
  3: {
    title: 'アイデア×アイデア',
    mission: '新しいアイデアを生むために、要素と要素をかけ合わせる。',
    description:
      '出題されるお題に、「掛け算アイデア」を組み合わせることで、新たなお題のアイデアを考案しましょう。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
};

export const InstructionColor: InstructionColors = {
  primary: '#FFB943',
  secondary: '#F2A118',
  finishButton: '#DD900D',
  shadow: '#D28400',
};
