import { CommonBG } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Create2Answer from './Create2Answer';

interface Stage2Props {
  stage: number;
}

const Stage2: React.FC<Stage2Props> = props => {
  return (
    <CommonBG>
      <MainWrapper>
        <Create2Answer turn={props.stage - 1} />
      </MainWrapper>
    </CommonBG>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 4rem);
  margin: 4rem auto 0;
  display: flex;
  overflow-y: scroll;
`;

export default Stage2;
