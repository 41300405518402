type Stage = 'create1' | 'create2' | 'create3';

const create1KeyList = (trainingId: string) => [
  `@useStatePerist:create-${trainingId}-resultSet`,
  `@useStatePerist:create1-${trainingId}-sendCount`,
  `@useStatePerist:create1-${trainingId}-tempIdea`,
  `@useStatePerist:create1-${trainingId}-tempIdeasVec`,
  `@useStatePerist:create1-${trainingId}-tempPairsVec`,
  `@useStatePerist:create1-${trainingId}-finished`,
  'timer:' + trainingId + `-create1-0`,
  'timer:' + trainingId + `-create1-1`,
];

const create2KeyList = (trainingId: string) => [
  `@useStatePerist:create2-${trainingId}-tmpAns`,
  `@useStatePerist:create2-${trainingId}-finished`,
];

const create3KeyList = (trainingId: string) => [
  `@useStatePerist:create3-${trainingId}-tempIdea`,
  `@useStatePerist:create3-${trainingId}-tempIdeasVec`,
  `@useStatePerist:create3-${trainingId}-tempItemsVec`,
  `@useStatePerist:create3-${trainingId}-sendCount`,
  `@useStatePerist:create3-${trainingId}-finished`,
];
const keyLists = {
  create1: create1KeyList,
  create2: create2KeyList,
  create3: create3KeyList,
};

export const hasData = (stage: Stage, trainingId: string) => {
  const list = keyLists[stage](trainingId);
  return list.some(key => localStorage.getItem(key));
};
export const deleteData = (stage: Stage, trainingId: string) => {
  const list = keyLists[stage](trainingId);
  list.forEach(key => localStorage.removeItem(key));
};
