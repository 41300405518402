import 'firebase/auth'; // If you need it

import { useSignIn } from '@riddler-co-jp/specc-auth-client';
import React from 'react';
import { Navigate, Route, Routes, useMatch, useParams } from 'react-router-dom';

import {
  AuthContextType,
  DefaultAuthContext,
} from './components/auth/AuthContext';
import AuthErrorPage from './components/eventProvider/common/AuthErrorPage';
import FinishPage from './components/eventProvider/common/FinishPage';
import Loading from './components/eventProvider/common/Loading';
import RedirectPage from './components/eventProvider/common/RedirectPage';
import SelectStage from './components/eventProvider/common/SelectStage';
import StartPage from './components/eventProvider/common/StartPage';
import CreateCountDown from './components/eventProvider/CreateCountDown';
import Create1Game from './components/eventProvider/stagePages/create1/Create1Game';
import Create1Result from './components/eventProvider/stagePages/create1/Create1Result';
import Create1Rule from './components/eventProvider/stagePages/create1/Create1Rule';
import Create2Game from './components/eventProvider/stagePages/create2/Create2Game';
import Create2Result from './components/eventProvider/stagePages/create2/Create2Result';
import Create2Rule from './components/eventProvider/stagePages/create2/Create2Rule';
import Create3Game from './components/eventProvider/stagePages/create3/Create3Game';
import Create3Result from './components/eventProvider/stagePages/create3/Create3Result';
import Create3Rule from './components/eventProvider/stagePages/create3/Create3Rule';
import Create3Rule2 from './components/eventProvider/stagePages/create3/Create3Rule2';
import { app } from '.';

interface ConditionedRouteRule {
  condition: boolean;
  redirectPathOnFail: string;
}
interface ConditionedRouteProps {
  rules: ConditionedRouteRule[];
}

const ConditionedRoute: React.FC<ConditionedRouteProps> = props => {
  for (const rule of props.rules) {
    if (!rule.condition) {
      return <Navigate to={rule.redirectPathOnFail} />;
    }
  }

  return <>{props.children}</>;
};

export const AuthContext =
  React.createContext<AuthContextType>(DefaultAuthContext);

const debug = true;
interface RouterProps {}
export const TrainingRouter = () => {
  const { trainingId } = useParams<'trainingId'>();
  const match = useMatch('/:trainingId/*');
  const [signedIn, signInLoading] = useSignIn(
    app,
    'create',
    trainingId,
    match?.pathname === match?.pathnameBase ||
      match?.pathname === `${match?.pathnameBase}/`
  );
  const onlyValidUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    console.log(signInLoading, signedIn);
    return [
      {
        condition: debug || (!signInLoading && signedIn),
        redirectPathOnFail: '/auth-error',
      },
    ];
  }, [signInLoading, signedIn]);

  const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
    event.returnValue =
      'このページを再読み込みしますか？入力した内容は保存されません。';
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
  }, []);

  const onExit = (url: string) => {
    window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
    window.location.href = url;
  };

  if (signInLoading) {
    return <Loading />;
  }

  const page = match?.pathname.split('/') ?? [];
  if (['create1', 'create2', 'create3'].includes(page[2])) {
    const currentPage = page.slice(3).join('/');
    if (currentPage !== '' && !currentPage.match(/introduction/)) {
      localStorage.setItem('currentpage-' + page[2], currentPage);
    }
  }

  return (
    <Routes>
      {/* create1 */}
      <Route
        path='/create1/introduction'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create1Rule />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create1/game1'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create1Game stage={1} />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create1/game2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create1Game stage={2} />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create1/result'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create1Result />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create1/countdown1'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <CreateCountDown redirect='/create1/game1' />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create1/countdown2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <CreateCountDown redirect='/create1/game2' />
          </ConditionedRoute>
        }
      />

      {/* create2 */}
      <Route
        path='/create2/introduction'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create2Rule />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create2/countdown1'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <CreateCountDown redirect='/create2/game1' />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create2/countdown2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <CreateCountDown redirect='/create2/game2' />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create2/game1'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create2Game stage={1} />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create2/game2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create2Game stage={2} />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create2/result'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create2Result />
          </ConditionedRoute>
        }
      />

      {/* create3 */}
      <Route
        path='/create3/introduction'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create3Rule />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create3/countdown1'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <CreateCountDown redirect='/create3/game1' />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create3/game1'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create3Game stage={1} />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create3/introduction2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create3Rule2 />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create3/countdown2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <CreateCountDown redirect='/create3/game2' />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create3/game2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create3Game stage={2} />
          </ConditionedRoute>
        }
      />
      <Route
        path='/create3/result'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Create3Result />
          </ConditionedRoute>
        }
      />
      <Route
        path='/'
        element={
          signInLoading ? (
            <Loading />
          ) : (
            <ConditionedRoute rules={onlyValidUserRules}>
              <StartPage />
            </ConditionedRoute>
          )
        }
      />
      <Route
        path='/stages'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <SelectStage />
          </ConditionedRoute>
        }
      />
      <Route
        path='/finish'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <FinishPage onExit={onExit} />
          </ConditionedRoute>
        }
      />
      <Route path='/auth-error' element={<AuthErrorPage />} />
      <Route path='/' element={<RedirectPage />} />
      <Route path='/*' element={<Navigate to='/' />} />
    </Routes>
  );
};
