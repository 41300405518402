import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dots,
  FinishButton,
  Input,
  MainButton,
  TextWindow,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import {
  AnswerList,
  Counter,
  Result,
  SingleAnswer,
  TitleDetail,
} from '../../AnswerList';
import { titles } from '../../Words.json';

interface Stage2AnswerProps {
  turn: number;
}

export type Item = {
  title: string;
  ideas: string[];
};

export type pair = {
  id: number;
  name: string;
};

const MAX_ANSWER_LENGTH = 20;

const Create1Field: React.FC<Stage2AnswerProps> = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet, setResultSet] = useState<any>(
    `create-${trainingId}-resultSet`,
    { stage1: null }
  );
  console.log(location.state);
  const [sendCount, setSendCount] = useState<number>(
    `create1-${trainingId}-sendCount`,
    0
  );
  const [tempIdea, setTempIdea] = useState<string>(
    `create1-${trainingId}-tempIdea`,
    ''
  );
  const [tempIdeasVec, setTempIdeasVec] = useState<string[]>(
    `create1-${trainingId}-tempIdeasVec`,
    []
  );
  const [tempPairsVec, setTempPairsVec] = useState<pair[]>(
    `create1-${trainingId}-tempPairsVec`,
    []
  );
  const [finished, setFinished] = useState<boolean>(
    `create1-${trainingId}-finished`,
    false
  );
  const listRef = React.useRef<HTMLUListElement>(null);
  const listContentRef = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    setFinished(false);
  }, [pathname]);

  React.useEffect(() => {
    listRef.current?.scrollTo({
      top: listRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [sendCount]);

  const goTo = (url: string, params: any) => {
    window.setTimeout(() => {
      navigate(url, {
        state: params,
      });
    }, 0);
  };

  const send = () => {
    if (tempIdea === '') return;
    if (tempIdea.length > MAX_ANSWER_LENGTH) return;

    setTempIdeasVec([...tempIdeasVec, tempIdea]);
    const add: pair = { id: sendCount, name: tempIdea };
    setTempPairsVec([...tempPairsVec, add]);
    setSendCount(sendCount + 1);
    setTempIdea('');
  };

  const deleteIdea = (i: number) => {
    const tmp1 = tempIdeasVec;
    tmp1.splice(i, 1);
    setTempIdeasVec(tmp1);
    const tmp2 = tempPairsVec;
    tmp2.splice(i, 1);
    for (let i = 0; i < tmp2.length; i++) {
      tmp2[i].id = i;
    }
    setTempPairsVec(tmp2);
    setSendCount(sendCount - 1);
  };

  const Answers = tempPairsVec.map(p => {
    return (
      <SingleAnswer
        key={p.id}
        ref={listContentRef}
        animate={p.id == tempPairsVec.length - 1}
      >
        <div>
          <span>
            <p>{p.id + 1}</p>
          </span>
        </div>
        <div>
          <p>{p.name}</p>
          <div onClick={() => deleteIdea(p.id)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </SingleAnswer>
    );
  });

  const next = () => {
    const sendData: Item = {
      title: titles[props.turn],
      ideas: tempIdeasVec,
    };
    setSendCount(0);
    setTempIdea('');
    setTempPairsVec([]);
    setTempIdeasVec([]);
    setFinished(false);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const results: Item[] = resultSet?.stage1 ?? [];

    let _exist = false;
    results.map((single, i) => {
      if (single.title == sendData.title) {
        _exist = true;
        results[i] = sendData;
      }
    });

    if (_exist == false) {
      results.push(sendData);
    }

    console.log(results);
    setResultSet((prev: any) => {
      console.log(results, prev);
      return {
        ...prev,
        stage1: results,
      };
    });
    if (props.turn == 0) {
      goTo(`/${trainingId}/create1/countdown2`, { stage1: results });
    } else {
      goTo(`/${trainingId}/create1/result`, { stage1: results });
    }
  };

  return (
    <Wrap>
      <TimeGauge
        timerKey={trainingId + '-create1-' + props.turn}
        duration={300}
        stageName={InstructionText[1].title}
        logo={InstructionLogo}
        color={InstructionColor}
        onSkip={() => {
          next();
        }}
        onFinished={() => {
          setFinished(true);
        }}
        onBack={() => {
          navigate(`/${trainingId}/stages`);
        }}
      />
      <Wrapper>
        <Main>
          <ThemeWrap>
            <Theme>
              <h2>お題</h2>
              <div>
                <p>{titles[props.turn]}</p>
                <span>
                  <Dots
                    max={titles.length}
                    current={props.turn}
                    color={InstructionColor.primary}
                  />
                </span>
              </div>
            </Theme>

            <Ans>
              <TextWindow bracket>お題を構成する要素を書き出そう。</TextWindow>
              <AnsField>
                <Input
                  maxLength={MAX_ANSWER_LENGTH}
                  placeholder='要素を入力してください'
                  value={tempIdea}
                  onChange={(val: string) => {
                    setTempIdea(val);
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      send();
                    }
                  }}
                />
                <MainButton
                  color='positive'
                  size='medium'
                  disabled={
                    tempIdea == '' || tempIdea.length > MAX_ANSWER_LENGTH
                  }
                  onClick={() => send()}
                >
                  送信
                </MainButton>
              </AnsField>
            </Ans>
          </ThemeWrap>
        </Main>

        <Result>
          <h3>
            <TitleDetail />
          </h3>
          <AnswerList ref={listRef}>{Answers}</AnswerList>
          <Counter>
            <span>回答数</span>
            <p>{sendCount}</p>
          </Counter>
        </Result>
      </Wrapper>

      <FinishButton
        color={InstructionColor}
        finished={finished}
        click={() => {
          next();
        }}
      >
        {props.turn == 0
          ? '次のお題に挑戦しましょう！'
          : '以上で実習は終了です。お疲れ様でした！'}
      </FinishButton>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const Main = styled.div`
  flex: 1;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ThemeWrap = styled.div`
  padding-bottom: 20px;
`;

const Theme = styled.div`
  max-width: 600px;
  margin: 0 auto;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  position: relative;

  > div {
    border: solid 1px #ced4da;
    border-top: none;
    padding: 30px 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 110px;

    > span {
      position: absolute;
      bottom: 16px;
    }
  }

  p {
    text-align: center;
    font-size: 5.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h2 {
    background: #f2a118;
    color: white;
    font-size: 2.6rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 4px;
  }
`;

const Ans = styled.div`
  max-width: 600px;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    padding: 0 10px;
  }
`;

const AnsField = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  input {
    margin-top: 6px;
    text-align: center;
  }
  button {
    margin-left: 12px;
  }
`;

export default Create1Field;
