import styled from 'styled-components';

export const TitleDetail: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='257'
      height='42'
      viewBox='0 0 257 42'
    >
      <g transform='translate(29.605 0.938)'>
        <g transform='translate(0 0)'>
          <g transform='translate(0)'>
            <rect
              width='203'
              height='40'
              transform='translate(-29.605 0.063)'
              fill='#343a40'
            />
            <rect
              width='20'
              height='40'
              transform='translate(180.395 0.063)'
              fill='#343a40'
            />
            <rect
              width='20'
              height='40'
              transform='translate(207.395 0.063)'
              fill='#343a40'
            />
            <text
              transform='translate(74.395 32.063)'
              fill='#fff'
              fontSize='30'
              fontFamily='VDL-LogoJrBlack-Black, VDL-LogoJrBlack BK, sans-serif'
              fontWeight='800'
            >
              <tspan x='-90' y='0'>
                あなたの回答
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const AnswerKakeru: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
    >
      <path
        d='M-9919-6403.25l-7,7-2-2,7-7-7-7,2-2,7,7,7-7,2,2-7,7,7,7-2,2Z'
        transform='translate(9928.002 6414.25)'
        fill='#fff'
      />
    </svg>
  );
};

export const Result = styled.div`
  position: relative;
  width: 360px;
  background: rgba(206, 212, 218, 0.25);

  h3 {
    margin-top 20px;
  }
`;

export const Counter = styled.div`
  font-weight: bold;
  position: absolute;
  right: 28px;
  display: flex;
  align-items: baseline;
  bottom: 20px;

  span {
    font-size: 1.6rem;
    margin-right: 12px;
  }

  p {
    font-size: 6.6rem;
  }
`;

export const AnswerList = styled.ul`
  padding: 20px 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 210px);
`;

interface singleAnsProps {
  animate: boolean;
}

export const SingleAnswer = styled.li`
  ${(p: singleAnsProps) => {
    return p.animate
      ? `
      @keyframes show {
        0% {
          opacity: 0;
          transform: translateX(100px);
        }
        100% {
          opacity: 1;
          transform: translateX(0px);
        }
      }

      animation: show 0.2s both;
    `
      : ``;
  }}

  font-size: 1.6rem;
  list-style: none;
  background: white;
  display: flex;
  margin-bottom: 10px;

  > div:first-child {
    width: 40px;
    min-height: 40px;
    background: #343a40;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      text-align: center;
      color: white;
    }
  }

  > div:last-child {
    border: solid 1px #ced4da;
    border-left: none;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    > p {
      text-align: center;
      flex: 1;
      overflow-wrap: anywhere;
    }

    > div:last-child {
      padding: 4px 10px;
      cursor: pointer;
      opacity: 0;
      color: red;
    }
  }

  &:hover {
    > div:last-child {
      > div:last-child {
        opacity: 1;
      }
    }
  }
`;
