import {
  CommonBG,
  HeaderOptions,
  StageSelect,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  InstructionColor,
  InstructionLogo,
} from '../../../lib/instructionSettings';
import Dialog from '../../uiElements/Dialog';

const headerOptions: HeaderOptions = {
  hideHomeButton: true,
  hideSettingButton: true,
};

interface SelectStageProps {}
export const SelectStage: React.FC<SelectStageProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [resultSet] = React.useState<any>(location.state || { stage1: null });
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const { trainingId } = useParams<'trainingId'>();

  const goTo = (url: string, params: any) => {
    window.setTimeout(() => {
      navigate(url, {
        state: params,
      });
    }, 0);
  };

  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <StageSelect
        onBack={() => goTo(`/${trainingId}/`, resultSet)}
        onFinish={() => goTo(`/${trainingId}/finish`, resultSet)}
        onClick={(stage: number) => {
          if (stage == 0) {
            if (resultSet.stage1 == null) {
              goTo(`/${trainingId}/create1/introduction`, resultSet);
            } else {
              setIsShowModal(true);
            }
          } else if (stage == 1) {
            goTo(`/${trainingId}/create2/introduction`, resultSet);
          } else if (stage == 2) {
            goTo(`/${trainingId}/create3/introduction`, resultSet);
          }
        }}
        stages={[
          {
            name: '素材分解\nトライアル',
            path: '/' + trainingId + '/create1/introduction',
          },
          {
            name: 'チェックリスト\n大喜利',
            path: '/' + trainingId + '/create2/introduction',
          },
          {
            name: 'アイデア×アイデア',
            path: '/' + trainingId + '/create3/introduction',
          },
        ]}
        color={InstructionColor}
        logo={InstructionLogo}
      />
      <Dialog
        message={
          '前回の記録が上書きされます。よろしいですか？\n（実習1で記入した内容を実習2で使用します）'
        }
        isShow={isShowModal}
        cancelText={'キャンセル'}
        onCancel={() => setIsShowModal(false)}
        onConfirm={() => {
          goTo(`/${trainingId}/create1/introduction`, resultSet);
        }}
      />
    </CommonBG>
  );
};
export default SelectStage;
